import { useEffect } from 'react'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'

import { getApproxPrice } from 'app/features/Profile/Auction/helpers/auctions.helpers'
import Actioncable from 'app/services/Actioncable'
import {
  getAwsConfigData,
  getCartListings,
  getCartListingsCount,
  getCartTotalPrice,
  getLikedListings,
  getLikedListingsBySlug,
  getListing,
  getListingsByUser,
  getListingsPriceRang,
  getSimilarListings,
  getTradePriceAllListings,
  getUserListing,
  getUserListings,
  getViewedListings,
  getVinNumber
} from 'app/services/Api/listing'
import { getVehicleLikedAvailableListings } from 'app/services/Api/user'
import Deserializer from 'app/services/Deserializer'
import useAuthStore, {
  getAuthUserId,
  getIsAuthenticated
} from 'app/store/auth.store'
import { transformPartNumber } from 'app/utils/helpers/transforms.helper'
import { hasChannelUnsubscribe, isOfType } from 'app/utils/helpers/type.helpers'

import { listingKeys } from './listing.keys'

export const listingConfig = {
  getUserListing: {
    key: listingKeys.getUserListing,
    request: async ({ queryKey }) => {
      const [_key, _, slug] = queryKey

      const resp = await getUserListing(slug)

      return resp
    }
  },
  getListing: {
    key: listingKeys.getListing,
    request: async ({ queryKey }) => {
      const [_key, _, slug] = queryKey

      const resp = await getListing(slug)

      return resp
    }
  },
  getListingsByUser: {
    key: listingKeys.getListingsByUser,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const searchByPartNumber = transformPartNumber(
        params?.search_by_part_number
      )

      const resp = await getListingsByUser({
        ...params,
        search_by_part_number: searchByPartNumber
      })

      const listings = await Deserializer.deepDeserialize(resp?.listings)

      return { listings, part_numbers: resp?.part_numbers, pagy: resp?.pagy }
    }
  },
  getInfiniteListingsByUser: {
    key: listingKeys.getListingsByUser,
    request: async (queryParams) => {
      const { pageParam, queryKey } = queryParams
      const [_key, _, sortParams] = queryKey

      if (!isOfType.null(pageParam)) {
        const searchByPartNumber = transformPartNumber(
          sortParams?.search_by_part_number
        )

        const params = {
          ...sortParams,
          search_by_part_number: searchByPartNumber,
          page: pageParam || 1
        }

        const resp = await getListingsByUser(params)

        const listings = await Deserializer.deepDeserialize(resp?.listings)

        return { listings, part_numbers: resp?.part_numbers, pagy: resp?.pagy }
      }

      return {
        listings: [],
        part_numbers: [],
        pagy: {}
      }
    }
  },
  getListingsPriceRang: {
    key: listingKeys.getListingsPriceRang(),
    request: getListingsPriceRang
  },
  getUserListings: {
    key: listingKeys.getUserListings,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getUserListings(params)

      const listings = await Deserializer.deepDeserialize(resp?.listings)

      return { listings, pagy: resp?.pagy }
    }
  },
  getInfiniteUserListings: {
    key: listingKeys.getUserListings,
    request: async (queryParams) => {
      const { pageParam, queryKey } = queryParams
      const [_key, _, params] = queryKey

      const currentPageParam = pageParam ?? params?.page ?? 1

      if (!isOfType.null(currentPageParam)) {
        const resp = await getUserListings({
          ...params,
          page: currentPageParam
        })

        const listings = await Deserializer.deepDeserialize(resp?.listings)

        return { listings, pagy: resp?.pagy }
      }

      return { listings: [], pagy: {} }
    }
  },
  getViewedListings: {
    key: listingKeys.getViewedListings,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getViewedListings(params)

      return resp
    }
  },
  getAwsConfigData: {
    key: listingKeys.getAwsConfigData(),
    request: getAwsConfigData
  },
  getVehicleLikedAvailableListings: {
    key: listingKeys.getVehicleLikedAvailableListings,
    request: async ({ queryKey }) => {
      const [_key, _, vehicleSlug] = queryKey

      const resp = await getVehicleLikedAvailableListings(vehicleSlug)

      const avaliable_listings = await Deserializer.deepDeserialize(
        resp?.avaliable_listings
      )
      const liked_listings = await Deserializer.deepDeserialize(
        resp?.liked_listings
      )
      const vehicle = await Deserializer.deepDeserialize(resp?.vehicle)

      return {
        avaliable_listings,
        avaliable_listings_count: resp?.avaliable_listings_count,
        liked_listings,
        liked_listings_count: resp?.liked_listings_count,
        vehicle
      }
    }
  },
  getLikedListings: {
    key: listingKeys.getLikedListings,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getLikedListings(params)

      const listings = await Deserializer.deepDeserialize(resp?.listings)
      const vehicle = await Deserializer.deepDeserialize(resp?.vehicle)

      return { listings, vehicle, pagy: resp?.pagy }
    }
  },
  getInfiniteLikedListings: {
    key: listingKeys.getLikedListings,
    request: async (queryParams) => {
      const {
        queryKey: [_key, _, params],
        pageParam
      } = queryParams

      if (!isOfType.null(pageParam)) {
        const page = pageParam || params?.page || 1

        const resp = await getLikedListings({ ...params, page })

        const listings = await Deserializer.deepDeserialize(resp?.listings)
        const vehicle = !!params?.vehicle_slug
          ? await Deserializer.deepDeserialize(resp?.vehicle)
          : null

        return { listings, vehicle, pagy: resp?.pagy }
      }

      return { listings: [], vehicle: {}, pagy: {} }
    }
  },
  getLikedListingsBySlug: {
    key: listingKeys.getLikedListingsBySlug,
    request: async ({ queryKey }) => {
      const [_key, _, listingSlugs] = queryKey

      const resp = await getLikedListingsBySlug(listingSlugs)

      return resp?.listings
    }
  },
  getVinNumber: {
    key: listingKeys.getVinNumber,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getVinNumber(params)

      return resp.vehicle
    }
  },
  getCartListings: {
    key: listingKeys.getCartListings,
    request: async ({ queryKey }) => {
      const [_key, _, params] = queryKey

      const resp = await getCartListings(params)

      const listings = await Deserializer.deepDeserialize(resp?.cart_listings)

      return {
        cart_listings: listings,
        pagy: resp?.pagy,
        total_converted_price: resp?.total_converted_price
      }
    }
  },
  useGetCartTotalPrice: {
    key: listingKeys.getCartTotalPrice(),
    request: getCartTotalPrice
  },
  getInfiniteCartListings: {
    key: listingKeys.getCartListings,
    request: async (queryParams) => {
      const {
        queryKey: [_key, _, params],
        pageParam
      } = queryParams
      if (!isOfType.null(pageParam)) {
        const page = pageParam || params?.page || 1

        const resp = await getCartListings({
          ...params,
          page
        })

        const listings = await Deserializer.deepDeserialize(resp?.cart_listings)

        return {
          cart_listings: listings,
          pagy: resp?.pagy,
          total_converted_price: resp?.total_converted_price
        }
      }

      return { cart_listings: [], pagy: {}, total_converted_price: 0 }
    }
  },
  getCartListingsCount: {
    key: listingKeys.getCartListingsCount(),
    request: getCartListingsCount
  },
  getTradePriceAllListings: {
    key: listingKeys.getTradePriceAllListings(),
    request: getTradePriceAllListings
  },
  getSimilarListings: {
    key: listingKeys.getSimilarListings,
    request: async ({ queryKey }) => {
      const [_key, _, listingId] = queryKey

      const resp = await getSimilarListings(listingId)

      const listings = await Deserializer.deepDeserialize(resp?.listings)

      return listings
    }
  }
}

interface UseGetUserListing {
  slug: string
  options?: UseQueryOptions<ListingModel>
}

export const useGetUserListing = (props: UseGetUserListing) => {
  const { slug, options } = props
  const { getUserListing: config } = listingConfig

  const state = useQuery<ListingModel>(config.key(slug), config.request, {
    ...options
  })

  return state
}

interface UseGetListing {
  slug: string
  options?: UseQueryOptions<ListingModel>
}

export const useGetListing = (props: UseGetListing) => {
  const { slug, options } = props
  const { getListing: config } = listingConfig

  const state = useQuery<ListingModel>(config.key(slug), config.request, {
    ...options
  })

  const queryClient = useQueryClient()
  const isAuthenticated = useAuthStore(getIsAuthenticated)
  const userId = useAuthStore(getAuthUserId)
  const auctionId = state.data?.auction?.id || 0
  const isSubscribe = !!auctionId && isAuthenticated

  useEffect(() => {
    let channel: unknown = null

    if (!channel && isSubscribe) {
      Actioncable.subscribe(
        { channel: 'AuctionsNotificationsChannel', auction_id: auctionId },
        {
          received: async (data) => {
            await queryClient.setQueryData(
              config.key(slug),
              // @ts-ignore
              (oldData: ListingModel) => {
                if (!!data.actual_price) {
                  const { auction, ...otherListingData } = oldData

                  const convertedActualPrice = getApproxPrice({
                    price: data?.actual_price,
                    exchangeRate: oldData.exchange_rate
                  })

                  const isHighestBidder = userId === String(data?.user_id)
                  const outbid = !!oldData?.highest_bidder
                    ? !oldData?.outbid
                    : !!oldData?.outbid
                  const currentOutbid = isHighestBidder ? false : outbid
                  const maxBid = currentOutbid ? null : oldData?.max_bid

                  return {
                    ...otherListingData,
                    actual_price: data?.actual_price,
                    converted_actual_price: convertedActualPrice,
                    highest_bidder: isHighestBidder,
                    outbid: currentOutbid,
                    max_bid: maxBid,
                    auction: {
                      ...auction,
                      end_date: data?.end_date,
                      reserve_status: data?.reserve_status,
                      bids_count: data?.bids_count
                    }
                  }
                }

                return oldData
              }
            )
          }
        }
      ).then((c) => {
        channel = c
      })
    }

    return (): void => {
      if (hasChannelUnsubscribe(channel)) channel?.unsubscribe()
    }
  }, [isSubscribe])

  return state
}

interface UseGetListingsByUser {
  params: UserListingsParams
  options?: UseQueryOptions<PaginationListingModel>
}

export const useGetListingsByUser = (props: UseGetListingsByUser) => {
  const { params, options } = props
  const { getListingsByUser: config } = listingConfig

  const state = useQuery<PaginationListingModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetInfiniteListingsByUser {
  params: UserListingsParams
  options?: UseInfiniteQueryOptions<PaginationListingModel>
}

export const useGetInfiniteListingsByUser = (
  props: UseGetInfiniteListingsByUser
) => {
  const { params, options } = props
  const { getInfiniteListingsByUser: config } = listingConfig

  const state = useInfiniteQuery<PaginationListingModel>(
    config.key(params),
    config.request,
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev
    }
  )

  return state
}

interface UseGetListingsPriceRang {
  options?: UseQueryOptions<PriceRangModal>
}

export const useGetListingsPriceRang = (props: UseGetListingsPriceRang) => {
  const { options } = props
  const { getListingsPriceRang: config } = listingConfig

  const state = useQuery<PriceRangModal>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetUserListings {
  params: Omit<MyListingQueryParams, 'p'> & { page: number }
  options?: UseQueryOptions<PaginationListingModel>
}

export const useGetUserListings = (props: UseGetUserListings) => {
  const { params, options } = props
  const { getUserListings: config } = listingConfig

  const state = useQuery<PaginationListingModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )
  return state
}

interface UseGetInfiniteUserListings {
  params: Omit<MyListingQueryParams, 'p'> & { page: number }
  options?: UseInfiniteQueryOptions<PaginationListingModel>
}

export const useGetInfiniteUserListings = (
  props: UseGetInfiniteUserListings
) => {
  const { params, options } = props
  const { getInfiniteUserListings: config } = listingConfig

  const state = useInfiniteQuery<PaginationListingModel>(
    config.key(params),
    config.request,
    {
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev,
      ...options
    }
  )
  return state
}

interface UseGetViewedListings {
  params: ViewedListingsParams
  options?: UseQueryOptions<ListingModel[]>
}

export const useGetViewedListings = (props: UseGetViewedListings) => {
  const { params, options } = props
  const { getViewedListings: config } = listingConfig

  const state = useQuery<ListingModel[]>(config.key(params), config.request, {
    ...options
  })

  return state
}

interface UseGetAwsConfigData {
  options?: UseQueryOptions<UploadConfigData>
}

export const useGetAwsConfigData = (props?: UseGetAwsConfigData) => {
  const { options } = props || {}
  const { getAwsConfigData: config } = listingConfig

  const state = useQuery<UploadConfigData>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetVehicleLikedAvailableListingsProps {
  vehicleSlug: string
  options?: UseQueryOptions<VehicleLikedAvailableModel>
}

export const useGetVehicleLikedAvailableListings = (
  props: UseGetVehicleLikedAvailableListingsProps
) => {
  const { vehicleSlug, options } = props || {}
  const { getVehicleLikedAvailableListings: config } = listingConfig

  const state = useQuery<VehicleLikedAvailableModel>(
    config.key(vehicleSlug),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetLikedListingsProps {
  params: LikedListingsParams
  options?: UseQueryOptions<PaginationLikedListingsModel>
}

export const useGetLikedListings = (props: UseGetLikedListingsProps) => {
  const { params, options } = props || {}
  const { getLikedListings: config } = listingConfig

  const state = useQuery<PaginationLikedListingsModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetInfiniteLikedListingsProps {
  params: LikedListingsParams
  options?: UseInfiniteQueryOptions<PaginationLikedListingsModel>
}

export const useGetInfiniteLikedListings = (
  props: UseGetInfiniteLikedListingsProps
) => {
  const { params, options } = props || {}
  const { getInfiniteLikedListings: config } = listingConfig

  const state = useInfiniteQuery<PaginationLikedListingsModel>(
    config.key(params),
    config.request,
    {
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev,
      ...options
    }
  )

  return state
}

interface UseGetLikedListingsBySlugProps {
  listingSlugs: string[]
  options?: UseQueryOptions<LikedListingsBySlugModel['listings']>
}

export const useGetLikedListingsBySlug = (
  props: UseGetLikedListingsBySlugProps
) => {
  const { listingSlugs, options } = props || {}
  const { getLikedListingsBySlug: config } = listingConfig

  const state = useQuery<LikedListingsBySlugModel['listings']>(
    config.key(listingSlugs),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetVinNumber {
  params: { vin_number: string }
  options?: UseQueryOptions<VinNumberModel>
}

export const useGetVinNumber = (props: UseGetVinNumber) => {
  const { params, options } = props
  const { getVinNumber: config } = listingConfig

  const state = useQuery<VinNumberModel>(config.key(params), config.request, {
    ...options
  })

  return state
}

interface UseGetCartListings {
  params: { page: number }
  options?: UseQueryOptions<PaginationCartListingModel>
}

export const useGetCartListings = (props?: UseGetCartListings) => {
  const { params, options } = props || {}
  const { getCartListings: config } = listingConfig

  const state = useQuery<PaginationCartListingModel>(
    config.key(params),
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetCartTotalPrice {
  options?: UseQueryOptions<any>
}

export const useGetCartTotalPrice = (props?: UseGetCartTotalPrice) => {
  const { options } = props || {}
  const { useGetCartTotalPrice: config } = listingConfig

  const state = useQuery<GetCartTotalPriceModel>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetInfiniteCartListingsProps {
  params?: { page: number }
  options?: UseInfiniteQueryOptions<PaginationCartListingModel>
}

export const useGetInfiniteCartListings = (
  props: UseGetInfiniteCartListingsProps
) => {
  const { params, options } = props || {}
  const { getInfiniteCartListings: config } = listingConfig

  const state = useInfiniteQuery<PaginationCartListingModel>(
    config.key(params),
    config.request,
    {
      getNextPageParam: (lastPage) => lastPage.pagy?.next,
      getPreviousPageParam: (lastPage) => lastPage.pagy?.prev,
      ...options
    }
  )

  return state
}

interface UseGetCartListingsCountProps {
  options?: UseQueryOptions<{ cart_listings_count: number }>
}

export const useGetCartListingsCount = (
  props?: UseGetCartListingsCountProps
) => {
  const { options } = props || {}
  const { getCartListingsCount: config } = listingConfig

  const state = useQuery<{ cart_listings_count: number }>(
    config.key,
    config.request,
    {
      ...options
    }
  )

  return state
}

interface UseGetTradePriceAllListings {
  options?: UseQueryOptions<TradePriceModel>
}

export const useGetTradePriceAllListings = (
  props?: UseGetTradePriceAllListings
) => {
  const { options } = props || {}
  const { getTradePriceAllListings: config } = listingConfig

  const state = useQuery<TradePriceModel>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetSimilarListingsProps {
  listingId: string
  options?: UseQueryOptions<ListingModel[]>
}

export const useGetSimilarListings = (props?: UseGetSimilarListingsProps) => {
  const { listingId = '', options } = props || {}
  const { getSimilarListings: config } = listingConfig

  const state = useQuery<ListingModel[]>(
    config.key(listingId),
    config.request,
    {
      ...options
    }
  )

  return state
}

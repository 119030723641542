import { FormattedMessage } from 'react-intl'
import { InfiniteData } from 'react-query'

import Text from 'app/components/base/Text'
import { BID_STATUS } from 'app/utils/constants/status.constants'

export const generateBidStatus = (status: BidStatuses) => {
  const statuses = {
    [BID_STATUS.winner]: (
      <Text
        variant="body2"
        sx={{
          fontWeight: 500,
          color: 'secondary.main'
        }}
      >
        <FormattedMessage defaultMessage="Winner" />
      </Text>
    ),
    [BID_STATUS.notPaid]: (
      <Text
        variant="body2"
        sx={{
          fontWeight: 500,
          color: 'error.main'
        }}
      >
        <FormattedMessage defaultMessage="Not paid" />
      </Text>
    )
  }

  return statuses[status]
}

export const selectAuctions = (data?: InfiniteData<PaginationAuctionModel>) => {
  return {
    auctions: data?.pages?.flatMap((page) => page.auctions),
    itemsCount: data?.pages[0]?.items_count || 0,
    pagy: data?.pages[0]?.pagy || { count: 0 },
    pageParams: data?.pageParams
  }
}

export const selectWatchlistAuctions = (
  data?: InfiniteData<PaginationWatchListModel>
) => {
  return {
    watchlist: data?.pages?.flatMap((page) => page.watchlist),
    itemsCount: data?.pages[0]?.items_count || 0,
    pagy: data?.pages[0]?.pagy || { count: 0 },
    pageParams: data?.pageParams
  }
}

export const getApproxPrice = ({
  price,
  exchangeRate
}: {
  price: string
  exchangeRate: number
}) => {
  const approxPrice = Number(price) * exchangeRate

  return Number(approxPrice.toFixed(2))
}
